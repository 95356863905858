import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as atlas from "azure-maps-control";

import { IFireDetailSmokeCheck, IClient, IPerimeterLocation, IUserInfo, IGeoData, IPreviousThreat, IProperty, IGeotabEngine, IPropertyInFire, IPropertyInFireForCsv } from "api/models";
import { IFireDataTable } from 'api/sitMapAPI';
import { IPropertyInFireParam, getPropertyInFire, getResourceOrders } from "api/smokeCheckAPI";

import { ActiveFireEngineIcon, VisitedFlagIcon, RehabRequiredFlagIcon } from "assets/img/mapSymbols";
import {  OneMileBuffer, ThreeMileBuffer, AlertDistance, Dot5MileBuffer, FirePerimeter, PreviousFires, ResEnrolled, ResNotEnrolled, ThreatPerimeter, ResOther, CommEnrolled, CommNotEnrolled, CommOther, AgriEnrolled, AgriNotEnrolled, AgriOther, MguEnrolled } from "../../assets/img";

interface ISmokeCheckState {
    allPolicyholders?: IFireDataTable[],
    allPreviousThreat?: IGeoData<IPreviousThreat>,
    filteredPropertiesInFire?: IPropertyInFire[],
    clientsByFire?: IClient[],
    currentUserInfo?: IUserInfo,
    drawingThreatPerimeter?: atlas.data.Geometry,
    engines?: IGeoData<IGeotabEngine>,
    enginesByFire?: [],
    exportData: IPropertyInFireForCsv[],
    firstObsId?: number,
    hasMapError?: boolean,
    legendItems: { text: string, imgSrc?: string, color?: string }[],
    modis?: atlas.data.FeatureCollection,
    policyholders?: IFireDataTable[],
    previousFirePerimeters?: IPerimeterLocation[],
    propertiesInFire?: IPropertyInFire[],
    propertiesInTwentyMiles?: IGeoData<IProperty>,
    resourceOrders?: [],
    scFireHistory?: [],
    selectedClients: number[],
    selectedLayerIds?: string[],
    selectedPreviousThreat?: IGeoData<IPreviousThreat>,
    showThreatCreate?: boolean,
    viirs?: atlas.data.FeatureCollection,
    wdsFireDetail?: IFireDetailSmokeCheck,
    wdsFirePerimeter?: atlas.data.FeatureCollection,
    zipCodes?: string[]
}

const initialState: ISmokeCheckState = {
    allPreviousThreat: {},
    filteredPropertiesInFire: [],
    clientsByFire: [],
    engines: {},
    enginesByFire: [],
    exportData: [],
    hasMapError: false,
    legendItems: [
        { text: "Fire Perimeter", imgSrc: FirePerimeter },
        { text: "Threat Perimeter", imgSrc: ThreatPerimeter },
        { text: ".5 Mile Buffer", imgSrc: Dot5MileBuffer },
        { text: "1 Mile Buffer", imgSrc: OneMileBuffer },
        { text: "3 Mile Buffer", imgSrc: ThreeMileBuffer },
        { text: "Alert Distance", imgSrc: AlertDistance },
        { text: "Previous Fires", imgSrc: PreviousFires },
        { text: "Res. Enrolled", imgSrc: ResEnrolled },
        { text: "Res. Not Enrolled", imgSrc: ResNotEnrolled },
        { text: "Res. Other", imgSrc: ResOther },
        { text: "Comm. Enrolled", imgSrc: CommEnrolled },
        { text: "Comm. Not Enrolled", imgSrc: CommNotEnrolled },
        { text: "Comm. Other", imgSrc: CommOther },
        { text: "Agri. Enrolled", imgSrc: AgriEnrolled },
        { text: "Agri. Not Enrolled", imgSrc: AgriNotEnrolled },
        { text: "Agri. Other", imgSrc: AgriOther },
        { text: "MGU", imgSrc: MguEnrolled },
        { text: "Visited Property", imgSrc: VisitedFlagIcon },
        { text: "Rehab Required", imgSrc: RehabRequiredFlagIcon },
        { text: "Engine", imgSrc: ActiveFireEngineIcon },
    ],
    modis: <any>{},
    previousFirePerimeters: [],
    propertiesInTwentyMiles: {},
    resourceOrders: [],
    scFireHistory: [],
    selectedClients: [],
    selectedPreviousThreat: {},
    showThreatCreate: false,
    viirs: <any>{}, 
    wdsFireDetail: {},
    wdsFirePerimeter: undefined,
    zipCodes: []
}

export const fetchResourceOrders = createAsyncThunk('smokeCheckActions/fetchResouceOrders', async (fireId: any) => {
    let response = await getResourceOrders(fireId);
    console.log(response);
    return response || [];
});

export const getPropertiesInFireByFireClient = createAsyncThunk("actions/getPropertyInFire", async (param: IPropertyInFireParam) => {
    let response = await getPropertyInFire(param);
    return response || [];
});

const smokeCheckActions = createSlice({
    name: "smokeCheckActions",
    initialState,
    reducers: {
        setFireDetail: (state, action) => {
            state.wdsFireDetail = action.payload;
        },
        setSelectedLayerIds: (state, action) => {
            state.selectedLayerIds = action.payload;
        },
        setSelectedClients: (state, action) => {
            state.selectedClients = action.payload;
        },
        setClientsByFire: (state, action) => {
            state.clientsByFire = action.payload;
        },
        setFirePerimeters: (state, action) => {
            state.wdsFirePerimeter = action.payload;
        },
        setPreviousFirePerimeters: (state, action) => {
            state.previousFirePerimeters = action.payload;
        },
        setCurrentUserInfo: (state, action) => {
            state.currentUserInfo = action.payload;
        },
        setFirstObsId: (state, action) => {
            state.firstObsId = action.payload;
        },
        setShowThreatCreate: (state, action) => {
            state.showThreatCreate = action.payload;
        },
        setAllPreviousThreat: (state, action) => {
            state.allPreviousThreat = action.payload;
        },
        setSelectedPreviousThreat: (state, action) => {
            state.selectedPreviousThreat = action.payload;
        },
        setDrawingThreatPerimeter: (state, action) => {
            state.drawingThreatPerimeter = action.payload;
        },
        setPropertiesInTwentyMiles: (state, action) => {
            state.propertiesInTwentyMiles = action.payload;
        },
        setEnginesByFire: (state, action) => {
            state.enginesByFire = action.payload;
        },
        setHasMapError: (state, action) => {
            state.hasMapError = action.payload;
        },
        setGeotabEngines: (state, action) => {
            state.engines = action.payload;
        },
        setViirs: (state, action) => {
            state.viirs = action.payload;
        },
        setModis: (state, action) => {
            state.modis = action.payload;
        },
        setFireHistory: (state, action) => {
            state.scFireHistory = action.payload;
        },
        setZipCodes: (state, action) => {
            state.zipCodes = action.payload;
        },
        setPropertiesInFire: (state, action) => {
            state.propertiesInFire = action.payload;
        },
        setFilteredPropertiesInFire: (state, action) => {
            state.filteredPropertiesInFire = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchResourceOrders.fulfilled, (state, action) => {
                state.resourceOrders = action.payload;
            })
            .addCase(getPropertiesInFireByFireClient.fulfilled, (state, action) => {
                state.propertiesInFire = action.payload;

                var exportDataArr: IPropertyInFireForCsv[] = [];

                for (const item of action.payload) {
                    exportDataArr.push({
                        policy: item.policy,
                        address1: item.address1,
                        address2: item.address2,
                        city: item.city,
                        state: item.state,
                        propertyType: item.propertyType,
                        enrolled: item.enrolled,
                        threatened: item.threatened,
                        zipCode: item.zipCode,
                        buildingCount: item.buildingCount,
                        producer: item.producer,
                        lineOfBusiness: item.lineOfBusiness,
                        agencyName: item.agencyName,
                        agencyCode: item.agencyCode,
                        agencyPhoneNumber: item.agencyPhoneNumber,
                        location: item.location,
                        lastPropertyStatus: item.lastPropertyStatus,
                        dateAction: item.dateAction,
                        client: item.client,
                        coverageAAmt: item.coverageAAmt,
                        distance: item.distance.toFixed(2)
                    });
                }

                state.exportData = exportDataArr;
            });
    }
});

export const {
    setFireDetail,
    setSelectedLayerIds,
    setSelectedClients,
    setClientsByFire,
    setFirePerimeters,
    setPreviousFirePerimeters,
    setCurrentUserInfo,
    setFirstObsId,
    setShowThreatCreate,
    setAllPreviousThreat,
    setSelectedPreviousThreat,
    setDrawingThreatPerimeter,
    setPropertiesInTwentyMiles,
    setEnginesByFire,
    setHasMapError,
    setGeotabEngines,
    setViirs,
    setModis,
    setFireHistory,
    setZipCodes,
    setPropertiesInFire,
    setFilteredPropertiesInFire
} = smokeCheckActions.actions;

export default smokeCheckActions.reducer;