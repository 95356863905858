import { createSlice } from "@reduxjs/toolkit";

import demobilizedFire from "assets/img/legendSymbols/demobilized-fire.png";
import dispatchedFire from "assets/img/legendSymbols/dispatched-fire.png";
import monitoredFire from "assets/img/legendSymbols/monitored-fire.svg";
import nonDispatchedFire from "assets/img/legendSymbols/non-dispatchedfire.png";
import { ILayerSelector, ILayerSelectorSettings } from "api/models";
import { IProgramFireData, IEngineAssignments, IDaySummary } from "api/plpAPI";
import { Constants } from "utils/constants";
import { ICoordinates } from "../../components/plp/WheresTheFireDialog";

interface IPlpState {
    weatherHazards: { text: string, color: string }[];
    legendItems: { text: string, imgSrc?: string, color?: string }[];
    layersSelector: ILayerSelector;
    layerSettings: ILayerSelectorSettings;
    programFires?: IProgramFireData[];
    monitoredFires?: IProgramFireData[];
    monitoredAndNoteworthyFires?: IProgramFireData[];
    engineAssignments?: IEngineAssignments[];
    daySummary?: IDaySummary[];
    selectedClientId?: number;  //internal user, one client is selected
    selectedFire?: any;
    centeredAddressCoordinates?: ICoordinates;
}

const initialState: IPlpState = {
    weatherHazards: [
        { text: 'Brisk Wind Advisory', color: '#D2B48C' },
        { text: 'Dense Smoke Advisory', color: '#F0E68C' },
        { text: 'Excessive Heat Warning', color: '#C71585' },
        { text: 'Excessive Heat Watch', color: '#800000' },
        { text: 'Extreme Fire Danger', color: '#E9967A' },
        { text: 'Extreme Wind Warning', color: '#FF1493' },
        { text: 'Fire Warning', color: '#A0522D' },
        { text: 'Fire Weather Watch', color: '#FFDEAD' },
        { text: 'Heat Advisory', color: '#FF7F50' },
        { text: 'High Wind Warning', color: '#DAA520' },
        { text: 'High Wind Watch', color: '#B8860B' },
        { text: 'Red Flag Warning', color: '#FF1493' },
        { text: 'Severe Thunderstorm Warning', color: '#FFA500' },
        { text: 'Severe Thunderstorm Watch', color: '#DB7093' },
        { text: 'Wind Advisory', color: '#D2B48C' }
    ],
    legendItems: [
        { text: 'Dispatched Fire', imgSrc: dispatchedFire },
        { text: 'Non-Dispatched Fire', imgSrc: nonDispatchedFire },
        { text: 'Demobilized Fire', imgSrc: demobilizedFire },
        { text: 'Monitored Fire', imgSrc: monitoredFire },
        { text: 'Extreme', color: '#D84432' },
        { text: 'High', color: '#E18A46' },
        { text: 'Moderate', color: '#FFFC72' },
        { text: 'Low', color: '#6CA243' },
    ],
    layersSelector: {
        fireTypes: [
            { text: "Program Fire", value: Constants.FireType_ProgramFire },
            { text: "Monitored Fire", value: Constants.FireType_MonitoredFire }
        ],
        timeFrame: [
            { text: '24 hours', value: '24' },
            { text: '7 days', value: '168' },
            { text: '2 weeks', value: '336' },
        ],
        layers: [
            { text: 'Regional Danger', value: Constants.Layer_RegionalDanger },
            { text: 'Smoke', value: Constants.Layer_Smoke },
            { text: 'Weather Hazards', value: Constants.Layer_WeatherHazards },
        ]
    },
    layerSettings: {
        fireTypes: [Constants.FireType_ProgramFire],
        timeFrame: '168',
        layers: [Constants.Layer_RegionalDanger]
    },
    centeredAddressCoordinates: {
        latitude: 0,
        longitude: 0
    },
    programFires: []
}

const plpActions = createSlice({
    name: "plpActions",
    initialState,
    reducers: {
        setLayerSettings: (state, action) => {
            state.layerSettings = action.payload;
        },
        setProgramFires: (state, action) => {
            state.programFires = action.payload;
        },
        setMonitoredFires: (state, action) => {
            state.monitoredFires = action.payload;
        },
        setMonitoredAndNoteworthyFires: (state, action) => {
            state.monitoredAndNoteworthyFires = action.payload;
        },
        setEngineAssignments: (state, action) => {
            state.engineAssignments = action.payload;
        },
        setPreviousDaySummar: (state, action) => {
            state.daySummary = action.payload;
        },
        setSelectedClientId: (state, action) => {
            state.selectedClientId = action.payload;
        },
        setSelectedFire: (state, action) => {
            let selectedFire = action.payload;
            let acreageContent = selectedFire.size ? `<br/>Acreage: ${selectedFire.size}` : "";
            let containmentContent = selectedFire.containment ? `<br/>${selectedFire.containment} Contained` : "";
            let commentsContent = selectedFire.comments && selectedFire.comments.length > 150 ? `<br/><br/>${selectedFire.comments.substring(0, 150)}...` : `<br/><br/>${selectedFire.comments}`;
            state.selectedFire = {
                ...action.payload,
                moreInfo: `${selectedFire.city}, ${selectedFire.state} ${acreageContent} ${containmentContent} ${commentsContent}`,
            }
        },
        setCenteredAddressCoordinates: (state, action) => {
            state.centeredAddressCoordinates = action.payload;
        },
    }
});

export const {
    setLayerSettings,
    setProgramFires,
    setMonitoredFires,
    setMonitoredAndNoteworthyFires,
    setEngineAssignments,
    setPreviousDaySummar,
    setSelectedClientId,
    setSelectedFire,
    setCenteredAddressCoordinates
} = plpActions.actions;
export default plpActions.reducer;