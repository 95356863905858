import { combineReducers } from '@reduxjs/toolkit'

import pha from 'features/pha/phaSlice';
import global from 'features/global/globalSlice';
import sitmap from "features/sitMap/sitMapSlice";
import plp from "features/plp/plpSlice";
import smokeCheck from "features/smokeCheck/smokeCheckSlice";
import admin from "features/admin/adminSlice";
import fireOverview from "../features/fireOverview/fireOverviewSlice";
import fire from "../features/fde/fireSlice";
import propertyQuery from "../components/fireOverview/propertyListTab/propertyQuerySlice";
import queryPropertyResult from "../components/fireOverview/propertyListTab/queryResultSlice";
import preFireInformation from "../components/fireOverview/preFireInforTab/preFireInforSlice";
import seeAllVisits from "../components/fireOverview/seeAllVisitsTab/seeAllVisitsSlice";
import tabControl from "../components/fireOverview/tabControlSlice";
import propertySearch from "../features/propertySearch/propertySearchSlice";
import programUse from "../features/programUse/programUseSlice";
import notifications from "../features/notifications/notificationsSlice";
import risk from "../features/risk/riskSlice";
import snapshot from "../components/notifications/mapsnap/snapshotSlice";
import historicFires from "../features/historicFires/historicFiresSlice";
import resourceTimeline from "../components/resourceStatus/timeline/timelineSlice";
import resourceMapFilter from "../components/resourceStatus/timeline/mapFilterSlice";
import resourceStatus from "../features/resourceStatus/resourceStatusSlice";
import resourceScheduling from '../features/resourceScheduling/resourceSchedulingSlice';
import scheduleCalendar from "../components/resourceScheduling/calendar/calendarSlice";
import workflowManagement from "../features/workflowManagement/workflowManagementSlice";
import mguProps from "../components/mguProps/mguPropsSlice";
import monitoringHeadquarters from "../features/monitoringHeadquarters/monitoringHeadquartersSlice";
import clientSubscription from "../features/clientSubscription/clientSubscriptionSlice";
import apiManagement from "../features/apiManagement/apiManagementSlice";
import workZones from '../features/workZones/workZonesSlice';
import clientProfile from '../components/clientProfile/clientProfileSlice';
import timeEngine from '../features/timeEngine/timeEngineSlice';

const rootReducers = combineReducers({
    pha,
    global,
    sitmap,
    plp,
    fire,
    smokeCheck,
    workZones,
    mguProps,
    admin,
    fireOverview,
    propertyQuery,
    queryPropertyResult,
    preFireInformation,
    seeAllVisits,
    tabControl,
    propertySearch,
    programUse,
    notifications,
    risk,
    snapshot,
    historicFires,
    resourceTimeline,
    resourceMapFilter,
    resourceStatus,    
    workflowManagement,
    monitoringHeadquarters,
    resourceScheduling,
    scheduleCalendar,
    clientSubscription,
    apiManagement,
    clientProfile,
    timeEngine
})

export default rootReducers